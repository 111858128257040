.tree-container {
	overflow-x: scroll;
	overflow-y: hidden;
}

.node circle {
	fill: #fff;
	stroke: steelblue;
}

.node text {
	font: 12px sans-serif;
}

.link {
	fill: none;
	stroke: #ccc;
}
.tooltip {
	padding: 10px 15px;
}
.tooltip li {
	list-style: none;
}

.solid {
	stroke: solid;
	fill: none;
}

.dashed {
	stroke-dasharray: 5, 5;
	fill: none;
}
